const form = document.getElementById('form-selector');


function numberWithCommas(x) {
    return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function percentageNumber(x) {
    return Math.round(x * 1000) / 10;
}

function scaleSliderValue(val, max, min, step) {
    const scaledValue = Math.pow(10, val / max * Math.log10(max / min)) * min;
    return Math.round(scaledValue / step) * step;
}

function getFormData() {

    const visitorsEl = form.elements['monthly-visitors'];
    const visitors = scaleSliderValue(Number(visitorsEl.value), visitorsEl.max, visitorsEl.min, 10000);

    const conversionRate = Number(form.elements['conversion-rate'].value) / 100;
    const aov = Number(form.elements['average-order-value'].value);
    const conversionRateImprovement = Number(form.elements['improvement'].value) / 100;

    const newConversionRate = conversionRate * (1 + conversionRateImprovement);

    const prevMonthlyRev = visitors * conversionRate * aov;
    const postMonthlyRev = visitors * newConversionRate * aov;

    const extraMonthlyRev = postMonthlyRev - prevMonthlyRev;

    const prevAnnualRev = prevMonthlyRev * 12;
    const postAnnualRev = postMonthlyRev * 12;

    const extraAnnualRev = postAnnualRev - prevAnnualRev;

    return { visitors, conversionRate, aov, conversionRateImprovement, postMonthlyRev, postAnnualRev, extraMonthlyRev, extraAnnualRev }
}



function updateGuarantee(improvement, extraAnnualRev) {
    const word = document.getElementById('guarantee-word');
    if (improvement == 0.1) {
        document.getElementById('calculator-guarantee').innerHTML = `We <span class="text-highlight">Guarantee</span> to make you<br/>
        <span class="text-highlight bigger-text">$${numberWithCommas(extraAnnualRev)} EXTRA Annual Revenue</span></span>`

    } else {
        document.getElementById('calculator-guarantee').innerHTML = `We will make you<br/>
        <span class="text-highlight bigger-text">$${numberWithCommas(extraAnnualRev)} EXTRA Annual Revenue</span></span>`

    }
}

function updateHTML() {

    const data = getFormData();

    //results
    document.getElementById('annual-revenue-result').innerHTML = `$${numberWithCommas(data.postAnnualRev)}`
    document.getElementById('monthly-revenue-result').innerHTML = `$${numberWithCommas(data.postMonthlyRev)}`
    document.getElementById('additional-monthly-revenue-result').innerHTML = `$${numberWithCommas(data.extraMonthlyRev)}`
    updateGuarantee(data.conversionRateImprovement, data.extraAnnualRev)

    //values
    document.getElementById('visitors-value').innerHTML = numberWithCommas(data.visitors);
    document.getElementById('conversion-rate-value').innerHTML = percentageNumber(data.conversionRate) + ' %';
    document.getElementById('aov-value').innerHTML = `$${numberWithCommas(data.aov)}`
    document.getElementById('improvement-value').innerHTML = percentageNumber(data.conversionRateImprovement) + ' %';

}


form.onchange = updateHTML;

updateHTML();